import Axios from 'axios';
import Vue from 'vue';
import { BASE_URL_MANAGER } from '@/config';

import { Component, Watch } from 'vue-property-decorator';
import { VueWizard } from '@/vue-wizard';
import AdminNavbar from '@/app/components/admin-navbar/AdminNavbar.vue';
import NavBar from '@/app/components/shared/nav-bar/NavBar.vue';
import moment from 'moment';
@Component({
  name: 'companies-management',
  components: { AdminNavbar, NavBar },
})
export default class CompaniesManagement extends VueWizard {
  /**
   * Bus para los eventos entre componentes
   */
  public EventBus: Vue = new Vue();

  public visible = false;

  public agentVisible = false;
  /**
   * objeto para el formulario
   */
  public company = {
    _id: null,
    name: '',
    country: '',
    phone: '',
  };

  public agent = {
    _id: null,
    name: '',
    lastName: '',
    password: '',
    password_confirmation: '',
  };

  public newAgent = {
    _id: null,
    username: '',
    name: '',
    password: '',
    confirmPassword: '',
  };

  public countries = [
    {
      label: 'Colombia',
      value: 'CO',
    },
    {
      label: 'Ecuador',
      value: 'EC',
    },
    {
      label: 'Panama',
      value: 'PA',
    },
    {
      label: 'Mexico',
      value: 'MX',
    },
    {
      label: 'Peru',
      value: 'PE',
    },
    {
      label: 'Chile',
      value: 'CL',
    },
    {
      label: 'España',
      value: 'ES',
    },
    {
      label: 'Paraguay',
      value: 'PY',
    },
  ];

  public rules = {
    name: [
      {
        required: true,
        message: 'El nombre es requerido',
        trigger: 'blur',
      },
      {
        min: 3,
        message: 'El nombre debe tener al menos 3 caracteres',
        trigger: 'blur',
      },
    ],
    country: [
      {
        required: true,
        message: 'El pais es requerido',
        trigger: 'blur',
      },
    ],
    phone: [
      {
        required: true,
        message: 'El telefono es requerido',
        trigger: 'blur',
      },
    ],
  };

  public rulesAgent = {
    name: [
      {
        required: true,
        message: 'El nombre es requerido',
        trigger: 'blur',
      },
      {
        min: 3,
        message: 'El nombre debe tener al menos 3 caracteres',
        trigger: 'blur',
      },
    ],
    password: [
      {
        required: true,
        trigger: 'change',
        // at least 8 characters, 1 uppercase, 1 lowercase and 1 number and no spaces allow ñ symbol
        pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[ A-Za-z0-9_@./#&+-ñÑ]{8,}$/,
        validator: (rule: any, value: any, callback: any) => {
          if (value === '') {
            callback(new Error('Ingrese una contraseña'));
          } else if (value.length < 8) {
            callback(new Error('Minimo 8 caracteres'));
          } else if (value.length > 50) {
            callback(new Error('Maximo 50 caracteres'));
          } else {
            callback();
          }
          // validate password_confirmation
          const form = this.$refs.editAgent as any;
          form.validateField('password_confirmation');
        },
      },
    ],
    // confirm_pasword must be the same as password
    // validate while typing
    password_confirmation: [
      {
        required: true,
        // message: 'Please input your password again',
        pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[ A-Za-z0-9_@./#&+-ñÑ]{8,}$/,
        trigger: 'change',
        validator: (rule: any, value: any, callback: any) => {
          if (value !== this.agent.password) {
            callback(new Error('Las contraseñas no coinciden'));
          } else if (value === '') {
            callback(new Error('Ingrese una contraseña'));
          } else if (value.length < 8) {
            callback(new Error('Minimo 8 caracteres'));
          } else if (value.length > 50) {
            callback(new Error('Maximo 50 caracteres'));
          } else {
            callback();
          }
        },
      },
    ],
  };

  /**
   * objeto de validacion de formulario
   */
  public fromValid = {
    _id: null,
    name: '',
  };

  /**
   * lista de compañias
   */
  public companies: any[] = [];
  public innerCompanies: any[] = [];
  public json_fields: any = {
    name: 'name',
    created_at: 'createdAt',
    count: 'count',
    phone: 'phone',
    dropiUser: 'dropiUser',
    phoneNumberId: 'phone_number_id',
    country: 'country',
  };

  public columns = [
    {
      title: 'Fecha creación',
      dataIndex: 'createdAt',
      key: 'createdAt',
      scopedSlots: { customRender: 'createdAt' },
      defaultSortOrder: 'descend',
      sorter: (a: any, b: any) => {
        return moment(a.createdAt).unix() - moment(b.createdAt).unix();
      },
    },
    {
      title: 'Nombre del proyecto',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Telefono',
      dataIndex: 'phone',
      key: 'phone',
    },
    {
      title: 'Phone Number Id',
      dataIndex: 'phone_number_id',
      key: 'phone_number_id',
    },
    {
      title: 'Bussines ID',
      dataIndex: 'bussines_id',
      key: 'bussines_id',
    },
    {
      title: 'Pais',
      dataIndex: 'country',
      key: 'country',
      filters: [
        {
          text: 'Colombia',
          value: 'CO',
        },
        {
          text: 'Ecuador',
          value: 'EC',
        },
        {
          text: 'Panama',
          value: 'PA',
        },
        {
          text: 'Mexico',
          value: 'MX',
        },
        {
          text: 'Peru',
          value: 'PE',
        },
        {
          text: 'Chile',
          value: 'CL',
        },
        {
          text: 'España',
          value: 'ES',
        },
        {
          text: 'Paraguay',
          value: 'PY',
        },
      ],
      onFilter: (value: any, record: any) =>
        record.country.indexOf(value) === 0,
    },
    {
      title: 'Acciones',
      dataIndex: 'actions',
      key: 'actions',
      scopedSlots: { customRender: 'actions' },
    },
  ];
  public innerColumns = [
    {
      title: 'Usuario',
      dataIndex: 'username',
      key: 'username',
    },
    {
      title: 'Nombre',
      dataIndex: 'name',
      key: 'name',
      scopedSlots: { customRender: 'name' },
    },
    {
      title: 'Rol',
      dataIndex: 'role',
      key: 'role',
    },
    {
      title: 'Acciones',
      dataIndex: 'actions',
      key: 'actions',
      scopedSlots: { customRender: 'actions' },
    },
  ];

  public loadingCompanies = false;

  public searchProject = '';
  public original_data_source = [
    {
      _id: '',
      name: '',
      createdAt: '',
      count: '',
      phone: '',
      dropiUser: '',
      phone_number_id: '',
      bussines_id: '',
      country: '',
      users: [
        {
          username: '',
          name: '',
          role: '',
        },
      ],
    },
  ];

  public totalProjects = 0;
  /**
   * metodo que se ejecuta al inciar el modulo
   */
  mounted(): void {
    this.list();
  }

  validateForm(prop: string, valid: boolean) {
    // validate the form
    const form = this.$refs.editCompany as any;
    form
      .validate()
      .then(async () => {
        await this.handleSubmit();
      })
      .catch(() => {});

    // return valid;
  }
  validateFormAgent(prop: string, valid: boolean) {
    // validate the form
    const form = this.$refs.editAgent as any;
    // if agent.change_password is true, validate password and password_confirmation
    // else validate only name and lastname
    form
      .validate()
      .then(async () => {
        await this.handleSubmitAgent();
      })
      .catch(() => {});
    // return valid;
  }
  closeModal() {
    const form = this.$refs.editCompany as any;
    form.resetFields();
    this.visible = false;
  }

  closeModalAgent() {
    const form = this.$refs.editAgent as any;
    form.resetFields();
    this.agentVisible = false;
  }

  /**
   * Procesa el formulario de creacion y edicion
   */
  notifySuccess(message: string, description: string) {
    this.$notification.success({
      placement: 'bottomRight',
      message,
      description,
    });
  }

  notifyError(message: string, description: string) {
    this.$notification.error({
      placement: 'bottomRight',
      message,
      description,
    });
  }

  async createCompany() {
    const url = `${BASE_URL_MANAGER}/company`;
    await Axios.post(url, this.company);
    this.notifySuccess(
      'Compañia creada!',
      `La compañia ${this.company.name}, se ha creado con exito!`,
    );
  }

  async updateCompany() {
    const url = `${BASE_URL_MANAGER}/company/${this.company._id}`;
    await Axios.patch(url, this.company);
    this.notifySuccess(
      'Compañia actualizada!',
      `La compañia ${this.company.name}, se ha modificado con exito!`,
    );
  }

  async handleSubmit(): Promise<void> {
    try {
      if (this.company._id === null) {
        await this.createCompany();
      } else {
        await this.updateCompany();
      }
      this.visible = false;
      this.list();
    } catch (err) {
      this.notifyError(
        'Ocurrio un error!',
        `La compañia ${this.company.name}, no ha podido ser procesada!`,
      );
      throw err;
    }
  }
  async handleSubmitAgent(): Promise<void> {
    if (!this.agent.password || this.agent.password_confirmation == '') {
      this.$notification.error({
        placement: 'bottomRight',
        message: 'Error',
        description: 'La contraseña no puede estar vacia',
      });
      return;
    }

    const dataToSend = {
      name: {
        firstName: this.agent.name,
        lastName: this.agent.lastName,
      },
      password: this.agent.password,
    };

    Axios.put(`${BASE_URL_MANAGER}/user/${this.agent!._id}`, dataToSend).then(
      async resp => {
        if (resp.data.success === true) {
          this.$notification.success({
            placement: 'bottomRight',
            message: 'Agente modificado exitosamente',
            description: 'Los datos han sido guardados',
          });
        } else {
          this.$notification.success({
            placement: 'bottomRight',
            message: 'Error',
            description:
              'Ha ocurrido un error al guardar los datos ' + resp.data!.message,
          });
        }
      },
    );
  }

  /**
   * TODO: VALIDAR DORMULARIO
   */
  /*checkFormValidity() {
        const valid = this.$refs.form.checkValidity()
        this.nameState = valid
        return valid
    }*/

  /**
   * establece la companis
   */
  setCompany(item: any) {
    this.company = item;
  }

  /**
   * abro el modal para edicion
   */
  edit(id: string): void {
    let url = `${BASE_URL_MANAGER}/company/${id}`;
    Axios.get(url)
      .then((res: any) => {
        this.company._id = res.data._id;
        this.company.name = res.data.name;
        this.$bvModal.show('companie-modal');
      })
      .catch(err => {
        this.$notification.error({
          placement: 'bottomRight',
          message: 'Ocurrio un error!',
          description: `error al traer la compañia!`,
        });
        throw err;
      });
  }
  /**
   * Listo las companies
   */
  list(): void {
    let url = `${BASE_URL_MANAGER}/company`;
    this.loadingCompanies = true;
    Axios.get(url)
      .then(res => {
        this.companies = res.data.companies;
        this.companies.sort((a, b) => {
          return moment(a.createdAt).unix() - moment(b.createdAt).unix();
        });

        this.original_data_source = [...this.companies];
        this.totalProjects = this.companies.length;
        console.log('companies', this.totalProjects);

        this.loadingCompanies = false;
      })
      .catch(err => {
        throw err;
      });
  }

  /**
   * cuando cierro el modal limpio el formulario
   */
  resetModal(): void {
    this.company._id = null;
    this.company.name = '';
  }
  /**
   *
   * @param bvModalEvt al presionar el boton de ok
   */
  handleOk(record: any): void {
    this.visible = true;
    this.company._id = record._id;
    this.company.name = record.name;
    this.company.country = record.country[0];
    this.company.phone = record.phone;
  }

  handleOkAgent(record: any): void {
    this.agentVisible = true;
    this.agent._id = record._id;
    this.agent.name = record.name.firstName;
    this.agent.lastName = record.name.lastName;
  }

  handleCancel(): void {
    this.agentVisible = false;
  }
  /**
   * procesa la eliiminacion
   */
  confirmDelete(bvModalEvt: any): void {
    bvModalEvt.preventDefault();

    let url = `${BASE_URL_MANAGER}/company/${this.company._id}`;
    Axios.delete(url)
      .then((err: any) => {
        this.$notification.success({
          placement: 'bottomRight',
          message: 'Compaía eliminada!',
          description: `La compañia ${this.company.name}, se ha eliminado con exito!`,
        });

        this.$bvModal.hide('confirm-modal');
        this.list();
      })
      .catch((err: any) => {
        this.$notification.error({
          placement: 'bottomRight',
          message: 'Ocurrio un error!',
          description: `La compañia ${this.company.name} no se ha eliminado!`,
        });
        throw err;
      });
  }

  /**
   * evento para el emiter del modal de crear admin
   */
  retrieveAdmins() {
    return;
  }

  exportExcel() {
    return true;
  }

  formatDate(date: any): string {
    return moment(String(date)).format('DD/MM/YYYY hh:mm:ss');
  }

  @Watch('searchProject')
  onSearchProjectChanged() {
    if (this.searchProject === '') {
      this.companies = [...this.original_data_source];
      this.totalProjects = this.companies.length;
      return;
    }
    // filter data_source by searchProject and state_selected values
    // seachProject is a string and can filter by name, email or country
    // state_selected is a string and can filter by status
    const data_source = this.original_data_source.filter(item => {
      return (
        item.name.includes(this.searchProject) ||
        item.country.includes(this.searchProject) ||
        item.phone_number_id.includes(this.searchProject) ||
        item.bussines_id.includes(this.searchProject) ||
        item.users.some(user => {
          return user.username.includes(this.searchProject);
        })
      );
    });

    this.companies = [...data_source];
    this.totalProjects = this.companies.length;
  }
  onChange(
    pagination: any,
    filters: any,
    sorter: any,
    { currentDataSource }: any,
  ) {
    if (filters.country.length > 0) {
      if (this.searchProject.length > 0) {
        this.totalProjects = currentDataSource.length;
      } else {
        this.totalProjects = currentDataSource.length;
      }
    } else {
      this.totalProjects = this.companies.length;
    }
  }
}
